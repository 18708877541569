<template>
    <div class="solution">
        <h2>ABSL une solution simple et efficace</h2>
        <ol>
            <li v-scroll-reveal="{ duration: 800, delay: 50, origin: 'bottom', distance: '50px'}">1- Présentez-nous simplement vos Besoins</li>
            <li v-scroll-reveal="{ duration: 800, delay: 150, origin: 'bottom', distance: '50px'}">2- Nous réalisons un diagnostic par téléphone, sur photo, ou surplace, nous vous proposons une Solution sur Mesure</li>
            <li v-scroll-reveal="{ duration: 800, delay: 250, origin: 'bottom', distance: '50px'}">3- Nous prenons en charge les autorisations administratives et programmons l’intervention sur votre site (IDF)</li>
            <li v-scroll-reveal="{ duration: 800, delay: 350, origin: 'bottom', distance: '50px'}">4- Nous vous transmettons sous 24h le rapport d’activité avec photos</li>
        </ol>
    </div>
</template>

<script>
export default {
    name: "solution"
}
</script>

<style scoped lang="scss">
   .solution {
       text-align: center;
       margin-bottom: 100px;
       h2 {
           color: #3c97f7;
           margin-bottom: 20px;
       }
       li {
           padding: 10px 0;
           font-weight: 500;
       }
   }
</style>