<template>
    <div class="elagage">
        <div class="header">
            <img :src="prestations[index].img" alt="video surveillance">
            <span class="sombre"></span>
            <h1>{{ prestations[index].title }}</h1>
        </div>
    </div>
</template>

<script>
export default {
    name: "Headerprestations",
    props: {
        index: Number
    },
    data() {
        return {
            prestations: [
                {
                    img: require('@/assets/Alarme.png'),
                    title: 'Alarmes / Vidéo-protection / télésurveillance',
                },
                {
                    img: require('@/assets/slider6.jpg'),
                    title: 'Portes blindées / Panneaux anti-intrusion',
                },
                {
                    img: require('@/assets/murage.jpg'),
                    title: 'Tôlage / Murage / Bardage',
                },
                {
                    img: require('@/assets/elagage.jpg'),
                    title: 'Nettoyage / Elagage / Démolition',
                },
                {
                    img: require('@/assets/elagage.jpg'),
                    title: 'Gardiennage',
                },
            ]
        }
    }
}
</script>

<style scoped lang="scss">
    .header {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 300px;
        overflow: hidden;

        img {
            position: absolute;
            width: 100%;
            min-height: 100%;
        }
        span {
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: #000;
            opacity: .6;
        }
        h1 {
            z-index: 1;
            color: #fff;
            text-transform: uppercase;
            font-weight: 900;
            font-size: 45px;
        }
    }
    @media screen and (max-width: 800px) {
        .header {
            height: 250px;

            h1 {
                font-size: 35px;
            }
        }
    }
    @media screen and (max-width: 600px) {
        .header {
            height: 200px;

            h1 {
                font-size: 25px;
            }
        }
    }
    @media screen and (max-width: 500px) {
        .header {
            height: 150px;

            h1 {
                font-size: 18px;
            }
        }
    }
</style>
