<template>
    <div class="elagage">
        <Headerprestations :index="3" />
         <div class="titre">
            <h2>Entretenir et sécuriser votre patrimoine Immeuble et terrain</h2>
            <p>Nettoyage terrains ou bâtiment - Démolition d’un Bâti existant</p>
            <small style="text-decoration: underline">ABSL La solution Global Service</small>
        </div>
        <div class="icon_container">
            <div class="icon" v-scroll-reveal="{ duration: 800, delay: 50, origin: 'bottom', distance: '50px'}">
                <img src="@/assets/branche.png" alt="chrono">
                <p>Elaguer vos sites</p>
            </div>
            <div class="icon" v-scroll-reveal="{ duration: 800, delay: 150, origin: 'bottom', distance: '50px'}">
                <img src="@/assets/balais.png" alt="sans-fil">
                <p>Nettoyer les terrains nus ou immeubles</p>
            </div>
            <div class="icon" v-scroll-reveal="{ duration: 800, delay: 250, origin: 'bottom', distance: '50px'}">
                <img src="@/assets/demolir.png" alt="chrono">
                <p>Démolir vos bâtis</p>
            </div>
            <div class="icon" v-scroll-reveal="{ duration: 800, delay: 250, origin: 'bottom', distance: '50px'}">
                <img src="@/assets/blocus.png" alt="chrono">
                <p>Protéger les zones à risque par nos solutions de palissade</p>
            </div>
        </div>
        <div class="images">
            <div class="AP">
                <img src="@/assets/elagageAV.png" alt="serrure">
            </div>
            <div class="AP">
                <img src="@/assets/elagage2.jpg" alt="porte blindée">
            </div>
            <div class="AP">
                
            </div>
            <div class="AP">
                <img src="@/assets/palissade.png" alt="palissade">
            </div>
        </div>
        <Solution/>
        <p class="devise">La sécurité à toutes vos portes c'est ABSL</p>
        <div class="appel">
            <p v-scroll-reveal="{ duration: 800, delay: 250, origin: 'left', distance: '300px'}">Un projet d'élagage / nettoyage / démolition / sécuriser ?</p>
            <router-link to="/contact" v-scroll-reveal="{ duration: 800, delay: 250, origin: 'right', distance: '300px'}">Contact</router-link>
        </div>
    </div>
</template>

<script>
import Headerprestations from '@/components/Headerprestations.vue'
import Solution from '@/components/ABSLSolution.vue'

export default {
    name: "elagage",
    components: {
        Headerprestations,
        Solution
    }
}
</script>

<style scoped lang="scss">
    .elagage {
        overflow: hidden;
    }
    .titre {
        text-align: center;
        h2 {
            margin-top: 50px;
            text-transform: uppercase;
        }
        p {
            margin-top: 3px;
            color: #3c97f7;
            font-weight: bold;
        }
        small {
            font-weight: bold;
            margin-top: 10px;
        }
    }
  .icon_container {
      margin-top: 120px;
      margin-bottom: 100px;
      width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;

      .icon {
          width: 20%;
          text-align: center;

          img {
              width: 55%;
          }
          p {
              font-size: 1.2rem;
              margin-top: 20px;
              text-align: center;
              font-weight: 900;
              color: #3c97f7;
          }
      }
  }
  .images {
      width: 90%;
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
  }
  .AP {
      width: 300px;
      height: 300px;
      position: relative;
      margin-bottom: 100px;
      overflow: hidden;
      border: 5px solid #3c97f7;
 
      img {
          position: absolute;
          width: 100%;
      }
  }
  .appel {
      width: 100%;
      height: 75px;
      background-color: #3c97f7;
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
          margin-left: 50px;
          color: #fff;
          font-weight: bold;
          font-size: 17px;
      }
      a {
          padding: 10px 40px;
          margin-right: 5%;
          text-decoration: none;
          color: #3c97f7;
          background-color: #fff;
          border-radius: 8px;
          font-weight: bold;
      }
  }
  @media screen and (max-width: 800px) {
      .titre {
          h2 {
              font-size: 20px;
          }
          p {
              font-size: 14px;
          }
      }
      .icon_container .icon p {
          font-size: 1rem;
      }
  }
  @media screen and (max-width: 600px) {
      .titre {
          h2 {
              font-size: 16px;
          }
          p {
              font-size: 13px;
          }
      }
      .icon_container {
          margin-top: 100px;
          margin-bottom: 120px;
      }
      .appel p {
          font-size: 14px;
      }
      .appel a {
          padding: 8px 20px;
          font-size: 14px;
      }
  }
  @media screen and (max-width: 500px) {
      .titre {
          h2 {
              font-size: 16px;
              margin: 50px 60px 0 60px;
          }
          p {
              font-size: 12px;
              margin: 10px 60px 0 60px;
              line-height: 20px;
          }
      }
      .icon_container {
          flex-direction: column;
          margin-top: 70px;
          margin-bottom: 100px;

          .icon {
              width: 60%;
              margin: 30px 0;

              img {
                  width: 40%;
              }
          }
      }
      .appel p {
          margin-left: 20px;
          font-size: 14px;
      }
      .appel a {
          padding: 8px 20px;
          font-size: 14px;
      }
  }
</style>
